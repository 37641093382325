import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController, NavParams, PopoverController } from '@ionic/angular';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import _ from 'lodash';
import moment from 'moment';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import {
  ANGULAR_EDITOR_CONFIG_DEFAULT,
  FEATURES,
  OUTBOUND_LEVEL,
  OUTBOUND_RECIPIENT_TYPE,
  OUTBOUND_STATUS,
  routes,
  TEMPLATE_TYPE,
} from 'src/app/constants';
import { createCommunityRecipient, Recipient } from 'src/app/utils/utils';
import { ListQueryResult, Template } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { MessageService } from 'src/services/message.service';
import { UploadService } from 'src/services/upload.service';

import { ActionDropdownComponent, ActionDropdownType } from '../action-dropdown/action-dropdown.component';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { UnlayerWrapperComponent } from '../unlayer-wrapper/unlayer-wrapper.component';

export interface ComposeComponentOptions {
  title: string;
  hideEmails: boolean;
}

function compareRecipients(r1: string | Recipient, r2: string | Recipient) {
  return (
    (typeof r1 === 'string' ? r1 : r1?.email ?? r1?.name) === (typeof r2 === 'string' ? r2 : r2?.email ?? r2?.name)
  );
}

@Component({
  selector: 'app-compose',
  templateUrl: './compose.page.html',
  styleUrls: ['./compose.page.scss'],
})
export class ComposePage implements OnInit {
  @ViewChild(UnlayerWrapperComponent) emailEditor: UnlayerWrapperComponent;
  showingTemplateEditor = false;

  editorConfig: AngularEditorConfig = {
    ...ANGULAR_EDITOR_CONFIG_DEFAULT,
    minHeight: '170px',
    upload: (file) => this.uploadService.uploadWysiwygFile(file),
    toolbarHiddenButtons: [
      ['undo', 'redo', 'strikeThrough', 'fontName', 'subscript', 'superscript', 'justifyFull'],
      [
        'fontSize',
        'unlink',
        'insertHorizontalRule',
        'backgroundColor',
        'removeFormat',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'textColor',
      ],
    ],
  };

  sendToSelf = true;
  isLoading = false;
  isSavingDraft = false;
  isSendingTest = false;
  isShowingRecipients = false;
  isShowingFrom = false;
  isReadOnly = false;
  isUploadingAttachment = false;
  showDraftSavedMessage = false;
  subject: string;
  message: string;
  options: ComposeComponentOptions;
  emails: (string | Recipient)[] = [];
  isScheduled: boolean = false;
  creatorName: string;
  replyTo: string;
  senderId: string;
  templates: Template[];
  templateId: string;
  outboundId: string;
  callback: () => void;
  runAt?: moment.Moment;
  initialRunAt?: moment.Moment;
  includeInternal = true;

  recipientOptions$: Observable<any[]>;
  recipientsLoading = false;
  recipientsInput$ = new Subject<string>();
  recpientSearchText = '';

  featureEnabled = true;

  attachments: any[] = [];

  constructor(
    navParams: NavParams,
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private popoverCtrl: PopoverController,
    private navCtrl: NavController,
    private apiService: ApiService,
    private uploadService: UploadService,
    public authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {
    this.emails = (navParams.get('emails') || []).filter((e) => e);
    this.senderId = navParams.get('senderId');
    this.replyTo = navParams.get('replyTo');
    this.callback = navParams.get('callback');
    this.options = navParams.get('options') || {
      hideEmails: false,
      title: 'Compose email',
    };

    const draft = navParams.get('draft');

    if (draft) {
      this.subject = draft.subject;
      this.message = draft.body;
      this.outboundId = draft.id;
      this.replyTo = draft.replyTo;
      this.creatorName = draft.creatorName;
      this.templateId = draft.metadata.templateId;
      this.isReadOnly = draft.status === 'sent';
      this.attachments = draft.attachments || [];

      if (draft.runAt) {
        this.initialRunAt = moment(draft.runAt);
        this.isScheduled = true;
      }

      draft.recipients.forEach((r) => {
        if (!r.type || r.type === OUTBOUND_RECIPIENT_TYPE.EMAIL) {
          this.emails.push({
            type: OUTBOUND_RECIPIENT_TYPE.EMAIL,
            email: r.email,
            name: r.email,
          });
        } else {
          this.emails.push(r);
        }
      });

      if (this.templateId) {
        setTimeout(() => {
          this.showingTemplateEditor = true;
        }, 50);
      }
    } else {
      const user = this.authService.user;

      if (!this.replyTo) {
        this.replyTo = user.email;
      }

      if (!this.creatorName) {
        this.creatorName = user.displayName;
      }
    }

    if (navParams.get('showTemplates')) {
      this.getTemplates();
    }

    if (!this.initialRunAt) {
      this.initialRunAt = moment().add(1, 'hours');
    }

    this.loadRecipients();
  }

  async ngOnInit() {
    this.isShowingRecipients = !this.emails?.length;

    this.featureEnabled = !this.authService.restrictedFeatures.includes(FEATURES.OUTBOUND_EMAIL);
  }

  editorLoaded() {
    setTimeout(() => {
      this.emailEditor.editor.loadDesign({
        html: this.message || '<div style="padding:10px;"></div>',
        classic: true,
      });

      this.emailEditor.editor.addEventListener('design:updated', () => {
        this.onChangeDetected();
      });
    }, 500);
  }

  async getTemplates() {
    const request = this.apiService.get(`/templates`, {
      communityId: '',
      type: TEMPLATE_TYPE.EMAIL,
    });

    request.subscribe(
      async (result: ListQueryResult) => {
        this.templates = result.rows;
      },
      async (err) => {
        await this.msgSrvc.showError(err);
      },
    );
  }

  addEmail() {
    if (!this.recpientSearchText || !this.recpientSearchText.length) {
      return;
    }

    this.emails.push({
      type: OUTBOUND_RECIPIENT_TYPE.EMAIL,
      email: this.recpientSearchText,
      name: this.recpientSearchText,
    });

    this.emails = _.uniqWith(this.emails, compareRecipients);

    this.onChangeDetected();
  }

  toggleShowingRecipients() {
    this.isShowingRecipients = !this.isShowingRecipients;
    this.addEmail();
  }

  toggleShowingFrom() {
    this.isShowingFrom = !this.isShowingFrom;
  }

  async applyTemplate() {
    const options: ActionDropdownType[] = this.templates.map((temp) => {
      return {
        label: temp.name,
      };
    });

    if (!options.length && this.authService.isCompanyAdmin) {
      options.push({
        label: 'Create a template',
        icon: 'add-circle-outline',
      });
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (idx: number, _label: string) => {
          popover.dismiss();

          if (!this.templates.length) {
            this.dismiss();
            this.navCtrl.navigateRoot(`${routes.DASHBOARD}/${routes.OUTBOUND}/templates`);
            return;
          }

          const template = this.templates[idx];
          this.templateId = template.id;
          this.message = template.html;

          this.showingTemplateEditor = false;
          setTimeout(() => {
            this.showingTemplateEditor = true;
          }, 50);

          this.onChangeDetected();
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  onChangeDetected() {
    if (!this.isReadOnly && this.subject) {
      this.saveDraft();
    }
  }

  sendTest() {
    this.send(true);
  }

  async parseEmailData() {
    if (!this.showingTemplateEditor) {
      return Promise.resolve();
    }

    return new Promise((resolve, _reject) => {
      this.emailEditor.editor.exportHtml((data) => {
        return resolve(data);
      });
    });
  }

  async send(isTest?: boolean) {
    if (!this.featureEnabled) {
      return;
    }

    if (isTest) {
      this.isSendingTest = true;
    } else {
      this.isLoading = true;
    }

    try {
      // Add remaining email that may be left in the text box
      this.addEmail();

      if (!isTest && !this.emails.length) {
        this.msgSrvc.show('Please add a recipient');
        this.isLoading = false;
        this.isSendingTest = false;
        return;
      }

      this.sendMessage(isTest);
    } catch (err) {
      this.msgSrvc.show(err.message || 'Unable to generate email content.');

      this.isLoading = false;
      this.isSendingTest = false;
    }
  }

  async sendMessage(isTest: boolean) {
    if (this.outboundId) {
      this.updateOutbound(false, isTest);
    } else {
      this.saveNewOutbound(false, isTest);
    }
  }

  async duplicate() {
    this.outboundId = undefined;
    this.isReadOnly = false;
    this.options.title = 'Compose email';
  }

  async saveDraft() {
    this.isSavingDraft = true;

    if (this.outboundId) {
      this.updateOutbound(true, false);
    } else {
      this.saveNewOutbound(true, false);
    }
  }

  async saveNewOutbound(draft: boolean, isTest: boolean) {
    const params = await this.getOutboundRequestParams(draft, isTest);

    if (!params) {
      return;
    }

    const request = this.apiService.post(`/outbounds`, params);

    request.subscribe(
      async (result: any) => {
        this.outboundId = result.id;
        this.didFinishSave(draft, isTest);
      },
      async (err) => {
        this.msgSrvc.showError(err);

        this.isLoading = false;
        this.isSavingDraft = false;
        this.isSendingTest = false;
      },
    );
  }

  async updateOutbound(draft: boolean, isTest: boolean) {
    const params = await this.getOutboundRequestParams(draft, isTest);
    const request = this.apiService.put(`/outbounds/${this.outboundId}`, params);

    request.subscribe(
      async (_result) => {
        this.didFinishSave(draft, isTest);
      },
      async (err) => {
        this.msgSrvc.show(err.message || 'Unable to send');

        this.isLoading = false;
        this.isSavingDraft = false;
        this.isSendingTest = false;
      },
    );
  }

  getEmailRecipients(isTest: boolean) {
    return isTest
      ? [
          {
            email: this.authService.user.email,
          },
        ]
      : this.emails.map((recipient) => {
          if (typeof recipient === 'string') {
            return {
              type: OUTBOUND_RECIPIENT_TYPE.EMAIL,
              email: recipient,
            };
          }

          if (!recipient.type || recipient.type === OUTBOUND_RECIPIENT_TYPE.EMAIL) {
            return {
              type: OUTBOUND_RECIPIENT_TYPE.EMAIL,
              email: recipient.name ?? recipient.email,
            };
          }

          return recipient;
        });
  }

  async getOutboundRequestParams(draft: boolean, isTest: boolean) {
    const user = this.authService.user;

    if (this.showingTemplateEditor) {
      const data: any = await this.parseEmailData();
      this.message = data.html;
    }

    if (!this.message && !draft && !isTest) {
      this.msgSrvc.show('Whoops! You forgot to add a message');
      this.isLoading = false;
      this.isSendingTest = false;
      return;
    }

    const emailData: any = {
      creatorName: this.creatorName || user.displayName,
      communityId: null,
      recipients: this.getEmailRecipients(isTest),
      body: this.message,
      status: draft ? OUTBOUND_STATUS.DRAFT : OUTBOUND_STATUS.SCHEDULED,
      deliveryMethod: 'email',
      isTest,
      level: OUTBOUND_LEVEL.TENANT,
      metadata: {},
      runAt: this.isScheduled && this.runAt ? this.runAt.toDate() : null,
    };

    if (this.subject) {
      emailData.subject = isTest ? `[TEST] ${this.subject}` : this.subject;
    }

    if (this.replyTo) {
      emailData.replyTo = this.replyTo;
    }

    if (this.senderId) {
      emailData.metadata.senderId = this.senderId;
    }

    if (this.templateId) {
      emailData.metadata.templateId = this.templateId;
    }

    if (this.attachments.length) {
      emailData.attachments = this.attachments;
    }

    emailData.metadata.excludeInternalUsers = !this.includeInternal;

    return emailData;
  }

  async didFinishSave(draft: boolean, isTest: boolean) {
    this.isLoading = false;
    this.isSavingDraft = false;
    this.isSendingTest = false;

    if (draft) {
      this.showDraftSavedMessage = true;
      setTimeout(() => {
        this.showDraftSavedMessage = false;
      }, 5000);
      return;
    }

    const successMessage = isTest
      ? 'Test email has been sent. Check your email.'
      : this.isScheduled
        ? 'Message scheduled'
        : 'Message sent!';

    this.msgSrvc.show(successMessage);

    if (!isTest) {
      this.dismiss();
    }
  }

  async discard() {
    if (this.outboundId) {
      this.isLoading = true;

      const request = this.apiService.delete(`/outbounds/${this.outboundId}`);

      request.subscribe(
        async () => {
          this.isLoading = false;
          this.isSendingTest = false;
          this.dismiss();
        },
        async (err) => {
          this.msgSrvc.show('Something went wrong, try again.');
          console.log('Error deleting draft: ', err);
          this.isLoading = false;
          this.isSendingTest = false;
        },
      );
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    if (this.callback) {
      this.callback();
    }

    this.modalCtrl.dismiss();
  }

  schedule() {
    this.isScheduled = true;
  }

  unschedule() {
    this.isScheduled = false;
  }

  getInitialDate() {
    return this.initialRunAt.format('YYYY-MM-DD');
  }

  getInitialTime() {
    return this.initialRunAt.format('HH:mm');
  }

  setRunAt([date, time]) {
    this.runAt = moment(`${date} ${time}`);
  }

  trackRecipientsByFn(item: any) {
    return item.id ?? item.email ?? item.name;
  }

  async loadRecipients() {
    const response: any = await this.apiService.postPromise(`/customers/list`, {});
    const customers = response.data;

    this.recipientOptions$ = concat(
      of([]), // default items
      this.recipientsInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.recipientsLoading = true)),
        switchMap((term) =>
          of(
            customers
              .filter((c) => (c.name ?? '').toLowerCase().includes((term ?? '').toLowerCase()))
              .map((c) => createCommunityRecipient(c)),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.recipientsLoading = false)),
          ),
        ),
      ),
    ) as Observable<any[]>;
  }

  recipientSearchBlurred() {
    // Reset the search after a little while. Have to wait because in some cases addEmail gets called
    // which needs access to the searchText before it gets cleared.
    setTimeout(() => {
      this.recpientSearchText = '';
    }, 250);
  }

  recipientsSearched(event) {
    this.recpientSearchText = event.term;
  }

  getRecipientCount() {
    return this.emails.reduce((acc, next) => {
      if (typeof next !== 'string' && next.type === OUTBOUND_RECIPIENT_TYPE.COMMUNITY) {
        return acc + (next.count || 0);
      }

      return acc + 1;
    }, 0);
  }

  async addAttachment() {
    const modal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps: {
        searchValue: '',
        helperText: '',
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    // if backdrop is clicked and no data passed
    if (!data || !data.uploadFile) {
      return;
    }

    this.isUploadingAttachment = true;

    this.uploadService
      .upload(
        [data.uploadFile],
        `email`,
        `${data.uploadFile.file.name}.${data.uploadFile.file.type?.split('/')?.[1] ?? 'png'}`,
      )
      .then(() => {
        const fileName = data.fileName;
        const imageUrl = data.uploadFile.url;

        this.attachments.push({
          name: fileName,
          url: imageUrl,
          type: data.uploadFile.file.type,
        });

        this.analyticsService.trackEvent('Email Composer', 'Attach file');
        this.isUploadingAttachment = false;
      })
      .catch((err) => {
        this.msgSrvc.show(err.message);
        this.isUploadingAttachment = false;
      });
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1);
  }
}
